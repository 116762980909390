import { StringType, NullType, checkString, checkBoolean, BooleanType } from "./DynamoDbDomain";

export type Testimonial = {
    Id: string,
    userId: string,
    statement: string,
    dateCreated: string,
    webVisibilityStatus: boolean,
    webVisibilityDisplay: string
}

type TestimonialServerModel = {
    Id: StringType,
    userId: StringType | NullType,
    statement: StringType | NullType,
    dateCreated: StringType | NullType,
    webVisibilityStatus: BooleanType | NullType
  }

export type TestimonialForOurPurposeServerModel = {
  Id: StringType,
  statement: StringType | NullType,
  dateCreated: StringType | NullType,
  webVisibilityStatus: BooleanType | NullType,
  representativeName: StringType | NullType,
  organizationName: StringType | NullType,
  logoUrl: string,
  logoS3Key: StringType | NullType
}


export type TestimonialForOurPurpose = {
  Id: string,
  statement: string,
  dateCreated: string,
  webVisibilityStatus: boolean,
  webVisibilityDisplay: string,
  representativeName: string,
  organizationName: string,
  logo: File
}
  
export const createTestimonialNew = (): Testimonial => {
  return {
    Id: "",
    userId: "",
    statement: "",
    dateCreated: (new Date().toDateString()),
    webVisibilityStatus: false
  } as Testimonial;
}

export const ConvertServerModelToTestimonial = (serverModel: TestimonialServerModel): Testimonial => {
    return {
      Id: serverModel.Id.S,
      userId: checkString(serverModel.userId),
      statement: checkString(serverModel.statement),
      dateCreated: checkString(serverModel.dateCreated),
      webVisibilityStatus: checkBoolean(serverModel.webVisibilityStatus),
      webVisibilityDisplay: checkBoolean(serverModel.webVisibilityStatus) ? "Yes" : "No"
    } as Testimonial
  }

export const ConvertServerModelToTestimonialForOurPurpose = async (serverModel: TestimonialForOurPurposeServerModel): Promise<TestimonialForOurPurpose> => {
  var logo = null;
  if(serverModel.logoS3Key){
    var response = await fetch(serverModel.logoUrl);
    let data = await response.blob();
    logo = new File([data], checkString(serverModel.logoS3Key));
  }
  return {
    Id: serverModel.Id.S,
    statement: checkString(serverModel.statement),
    dateCreated: checkString(serverModel.dateCreated),
    webVisibilityStatus: checkBoolean(serverModel.webVisibilityStatus),
    webVisibilityDisplay: checkBoolean(serverModel.webVisibilityStatus) ? "Yes" : "No",
    representativeName: checkString(serverModel.representativeName),
    organizationName: checkString(serverModel.organizationName),
    logo: logo,
    logoS3Key: checkString(serverModel.logoS3Key)
  } as TestimonialForOurPurpose
}