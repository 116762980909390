import { useEffect } from "react";
import { getStaticInformationOnLoad } from "../Clients/BeTheChangeApi";
import { StoreValue } from "../Clients";
import { CheckExists, StorageKey } from "../Clients/StorageClient";
interface IStaticInformationProvider {
    children: any;
}

const StaticInformationProvider: React.FC<IStaticInformationProvider> = (props) => {
    useEffect(() => {
        var doesTestExist = CheckExists(StorageKey.TestimonialsKey);
        var doesNUmExist = CheckExists(StorageKey.NumberOfDonorsKey);
        if(!doesTestExist || !doesNUmExist){
            getStaticInformationOnLoad().then(staticInfo => {
                StoreValue(StorageKey.TestimonialsKey, JSON.stringify(staticInfo?.testimonials))
                StoreValue(StorageKey.NumberOfDonorsKey, staticInfo?.currentMonthlyDonors?.toString())
            })
        }
    }, [])
  return (<>{props.children}</>);
};

export default StaticInformationProvider;