import emailjs from "@emailjs/browser";
import AssistanceRequest from "../Domain/AssistanceRequest";
import { ContactUsEmail } from "../Domain/ConteactUsEmail";
import SpeakingOpportunity from "../Domain/SpeakingOpportunity";
import { UserProfileType } from "../Domain/UserProfile";

const clientCode = "user_3IabaTk3eBEeonKL6yOxh";
const serviceId = "service_ku47cxh";

export const sendContactUsEmail = (email: ContactUsEmail) => {
  email.to_email_address = process.env.REACT_APP_ASSISTANCEREQUESTTOEMAIL ?? "";    
  emailjs.send(serviceId, "contactusemails", JSON.parse(JSON.stringify(email)), clientCode).catch((error: any) => {
    console.log(error.text);
  });
}

export const sendAssistanceRequestedEmail = (request: AssistanceRequest, emailAddress: string) => {
  var emailToSubmit = {
    to_email: process.env.REACT_APP_ASSISTANCEREQUESTTOEMAIL,
    from_name: request.representativeName,
    email_address: emailAddress,
    purpose: request.purpose,
    organization: request.organizationName,
    dateNeeded: request.requestedDistributionDate,
    amount: request.amountRequested,
  };
  if (request.amountRequested > 100) {
    var emailWithCClist = {
      ...emailToSubmit,
      cc_list: process.env.REACT_APP_BTCBOARDEMAIL!,
    };
    emailToSubmit = emailWithCClist;
  }
  emailjs.send(serviceId, "requestNotification", emailToSubmit, clientCode).catch((error: any) => {
    console.log(error.text);
  });
}

export const sendSpeakingOpportunityEmail = (speakingOpp: SpeakingOpportunity) => {
  var emailToSubmit = {
    from_name: speakingOpp.firstName + " " + speakingOpp.lastName,
    email_address: speakingOpp.emailAddress,
    message: speakingOpp.details,
    organization: speakingOpp.organizationName,
    dates: speakingOpp.dateRangeString,
  };

  emailjs.send(serviceId, "speakingemail", emailToSubmit,clientCode).catch((error: any) => {
    console.log(error.text);
  });
}

export const sendApprovalConfirmationEmail = (userProfile: UserProfileType) => {
  var emailToSubmit = {
    to_email: userProfile.emailAddress,
    representative_name: userProfile.representativeName,
    organization: userProfile.organizationName
  };

  emailjs.send(serviceId, "approvedNotification", emailToSubmit,clientCode).catch((error: any) => {
    console.log(error.text);
  });
}

export const sendAssistanceRequestApprovedEmail = (userEmail: string, assistanceRequest: AssistanceRequest, payoutNotes: string) => {
  var emailToSubmit = {
    to_email: userEmail,
    dollar_amount: assistanceRequest.amountRequested,
    notes: payoutNotes
  };

  emailjs.send(serviceId, "assistance_req_approval", emailToSubmit,clientCode).catch((error: any) => {
    console.log(error.text);
  });
}

export const sendAssistanceRequestNotApprovedEmail = (userEmail: string) => {
  var emailToSubmit = {
    to_email: userEmail
  };

  emailjs.send(serviceId, "assistance_req_denial", emailToSubmit,clientCode).catch((error: any) => {
    console.log(error.text);
  });
}
