import { Grid, Paper, styled } from "@mui/material";
import React from "react";
import beeLogo from "../Images/beeoutline2.png"

export interface ITestimonialCard {
  statement: string;
  logoUrl: string | null;
  organizationName: string;
  representativeName: string;
  dateEntered: Date;
}

const LogoStyled = styled("img")({
  width: "100%",
  height: "8vh",
  borderRadius: "50%",
  overflow: "hidden",
});

const TestimonialCard: React.FC<ITestimonialCard> = ({
  statement,
  logoUrl,
  organizationName,
  representativeName,
  dateEntered,
}: ITestimonialCard) => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Responsive layout
        padding: "1em",
        gap: "1em", // Optional: Adds spacing between items
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Custom shadow
        border: "1px solid rgba(0, 0, 0, 0.1)", // Optional border
        borderRadius: "8px", // Optional: Rounds the corners
      }}
    >
      {/* Logo and Info */}
      <Grid
        container
        item
        xs={12}
        md={4}
        alignItems="center"
        spacing={2}
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Grid container direction="row" alignItems="center" spacing={2}>
            {/* Logo */}
            <Grid item xs="auto">
              <LogoStyled src={logoUrl ?? beeLogo} />
              {/* <img src={beeLogo}/> */}
            </Grid>
            {/* Org Info */}
            <Grid item xs style={{ textAlign: "left" }}>
              <Grid container direction="column">
                <Grid item style={{fontWeight:500}}>{organizationName}</Grid>
                <Grid item style={{}}>{representativeName}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </Grid>

      {/* Statement: Dynamic placement */}
      <Grid
        item
        xs={12}
        md={6}
        style={{ textAlign: "left" }}
      >
        {statement}
      </Grid>
    </Paper>
  );
};

export default TestimonialCard;
