import React from 'react';
import { Member } from '../../Domain/BoardMember';
import { Modal, Fade, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IBoardMemberCardModal {
    member: Member;
    open: boolean;
    closeTriggered: () => void;
}

const Paper = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "50%",
    borderRadius: '8px',
    maxHeight: '80vh',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
        width: '75%',
    },
}));

const ModalContainer = styled(Modal)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ModalImage = styled('img')(({ theme }) => ({
    width: '20%',
    height: 'auto',
    maxHeight: 300,
    objectFit: 'cover',
    borderRadius: '50%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
        width: '45%',
    },
}));

const CloseButton = styled('button')({
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    fontSize: '1.5rem',
    color: '#000',
});
const NameStyle = styled(Grid)(() => ({
    fontWeight:"bold",
    fontSize:"1.5em"
}));
const TitleStyle = styled(Grid)(() => ({
    fontWeight:"bold",
    fontSize:"1em"
}));

const BoardMemberCardModal: React.FC<IBoardMemberCardModal> = ({ member, open, closeTriggered }) => {
    return (
        <ModalContainer
            open={open}
            onClose={closeTriggered}
            closeAfterTransition
        >
            <Fade in={open}>
                <Paper>
                    <CloseButton onClick={closeTriggered}>
                        &times;
                    </CloseButton>
                    <Grid container direction="column" justifyItems="flex-end" alignItems="flex-start">
                        <NameStyle item>{member.name}</NameStyle>
                        <TitleStyle>{member.title}</TitleStyle>
                        <Grid item><a href={"mailto:" + member.email}>{member.email}</a></Grid>
                    </Grid>
                    <ModalImage src={member.picture} alt={member.name} />
                    {member.bio && member.bio.length > 0 && member.bio.map((bioItem, index) => (
                        <p key={index}>{bioItem}</p>
                    ))}
                </Paper>
            </Fade>
        </ModalContainer>
    );
};

export default BoardMemberCardModal;
