import React from "react";
import mainLogoWithHugging from "../../Images/LogoMergedWithPeopleHugging.png";
import mainLogoWithHuggingMobile from "../../Images/LogoMergedWithPeopleHugging_Mobile.png";
import DonateOptions from "./DonateOptions";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import EventThankYouBanner from "../EventThankYouBanner";

const LargeImage = styled('img')({
  height: "100%",
  width: "100%",
});

const Splash: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container>
      <Grid item xs={12}>
        <LargeImage
          src={isMobile ? mainLogoWithHuggingMobile : mainLogoWithHugging}
          alt="Be The Change, GulfCoast"
        />
      </Grid>
      <Grid item xs={12}>
        <DonateOptions />
      </Grid>
      <Grid item xs={12}>
        <EventThankYouBanner />
      </Grid>
    </Grid>
  );
};

export default Splash;
