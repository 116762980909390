import React, { useContext } from "react";
import { AuthContext } from "../Authentication";
import { useNavigate } from "react-router-dom";
import { VerificationStatus } from "../../Domain/VerificationStatus";
import { Button, Grid, Paper, styled, Box } from "@mui/material";
import AssistanceRequests from "./AssistanceRequests";
import { RouteConstants } from "../../Constants/RouteConstants";
import UserProfile from "./UserProfile";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Testimonials from "./Testimonials";

const MainGridItemWithPadding = styled(Grid)({
  padding: "2vh"
});

const TitleGridItem = styled(Grid)({
  paddingTop: "1em",
  fontWeight: "bold",
  fontSize: "1.75vh"
});

const EditIconStyled = styled(EditIcon)({
  fontSize: '1.5rem',
  verticalAlign: 'middle',
  marginLeft: '0.5rem'
});

const AddIconStyled = styled(AddIcon)({
  fontSize: '1.5rem',
  verticalAlign: 'middle',
  marginLeft: '0.5rem'
});

const ScrollableContainer = styled(Box)({
  maxWidth:"100vw",
});

const Portal: React.FC = () => {
  const userFromContext = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <Grid container>
      <MainGridItemWithPadding item xs={12}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <>
              {userFromContext && userFromContext.userProfile && userFromContext.userProfile.verificationStatus === VerificationStatus.Approved && (
                <Grid container spacing={1}>
                  <Grid item>
                    <Paper>
                      <TitleGridItem item xs={12}>Profile</TitleGridItem>
                      <Grid item xs={12}>
                        <Button onClick={() => navigate(RouteConstants.Profile)}>
                          Edit Profile 
                          <EditIconStyled />
                        </Button>
                      </Grid>
                      <ScrollableContainer>
                        <UserProfile />
                      </ScrollableContainer>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Paper>
                          <TitleGridItem item xs={12}>Assistance Requests</TitleGridItem>
                          <Grid item xs={12}>
                            <Button onClick={() => navigate(RouteConstants.Apply)}>
                              New Assistance Request 
                              <AddIconStyled />
                            </Button>
                          </Grid>
                          <ScrollableContainer>
                            <AssistanceRequests />
                          </ScrollableContainer>
                        </Paper>
                      </Grid>
                      <Grid item>
                        <Paper>
                          <TitleGridItem item xs={12}>Testimonials</TitleGridItem>
                            <Grid item xs={12}>
                              <Button onClick={() => navigate(RouteConstants.AddTestimonial)}>
                                New Testimonial
                                <AddIconStyled />
                              </Button>
                            </Grid>
                            <ScrollableContainer>
                              <Testimonials />
                            </ScrollableContainer>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          </Grid>
        </Grid>
      </MainGridItemWithPadding>
    </Grid>
  );
};

export default Portal;
