import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Authentication";
import { CircularProgress } from "@mui/material";
import { HeadCell } from "../shared/EnhancedTable/HeadCell";
import EnhancedTable from "../shared/EnhancedTable/EnhancedTable";
import { Testimonial } from "../../Domain/Testimonial";
import { getTestimonials, updateWebVisibilityStatus } from "../../Clients/BeTheChangeApi";
import ExpandedTestimonial from "./ExpandedTestimonial";

const Testimonials: React.FC = () => {
  const user = useContext(AuthContext);
  const [currentTestimonialsList, setCurrentTestimonialsList] = useState<Testimonial[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        if (user?.accessToken) {
            var testimonialList = await getTestimonials(user.accessToken);
            setCurrentTestimonialsList(testimonialList);
            setLoading(false);
        }
      } catch (error) {
        console.error("Failed to fetch testimonials", error);
        setLoading(false);
      }
    };
    fetchRequests();
  }, [user]);

  const onVisibilityChanged = (testimonialId: string, isVisible: boolean) => {
    setCurrentTestimonialsList(prevTestimonials =>
      prevTestimonials.map(item => 
        item.Id === testimonialId ? { ...item, webVisibilityStatus: isVisible } : item
      )
    );
    if(user && user.accessToken){
      updateWebVisibilityStatus(user.accessToken, testimonialId, isVisible)
    }
  };

  const getExpandedData = (testimonial: Testimonial) => {
    return (
      <ExpandedTestimonial testimonial={testimonial} onVisibilityChanged={onVisibilityChanged}/>
    );
  };

  const headers: HeadCell<Testimonial>[] = [
    {
      id: "dateCreated",
      label: "Entered On"
    },
    {
      id: "statement",
      label: "Statement",
      preview: true
    },
    {
      id: "webVisibilityDisplay",
      label: "Display On Site"
    },
  ];

  return (
    <>    
      {loading && <CircularProgress />}
      {!loading && (
        <>
          <EnhancedTable
            rows={currentTestimonialsList}
            headCells={headers}
            initialOrderBy="dateCreated"
            enableFiltering={false}
            enablePaging={true}
            expandSection={getExpandedData}
          />
        </>
      )}
    </>
  );
};

export default Testimonials;