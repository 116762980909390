import BoardMembers from './BoardMembers';
import React from 'react';
import carol from '../../Images/carol.png';
import shelli from '../../Images/shelli.png';
import char from '../../Images/charlene.jpg';
import jeff from '../../Images/jeff.jpg';
import vicki from '../../Images/vicki.png';
import docia from '../../Images/docia.jpg';
import darrell from '../../Images/darrell.png'
import janet from '../../Images/janet.png'

import { Member } from '../../Domain/BoardMember';
const members = [
  {
    name: 'Shelli Bell',
    title: 'Founder & Executive Director',
    picture: shelli,
    bio: [
      'Shelli Bell is a native Mobilian. She and Troy, her soulmate of 18 years, have 5 children together and 14 grandchildren. She attended Gulf Coast Academy where she graduated as valedictorian. After raising her three children, she went on to obtain her B.S. from Faulkner University. In 2000, as a single mother of three children, working 2 two jobs, Shelli bought her first home. It was that experience in her life that has made her want to help the community, as well as help those that think home ownership is out of reach. Prior to becoming a Realtor, Shelli worked in the addictions field as a Crisis Director and Counselor, working overtime to make sure that the clients and the inpatient facilities enjoyed home cooked meals and had someone to pray with. She has worked for both non-profit and for-profit agencies.',
      'Because Mobile is her home, Shelli takes pride in being a part of her community and building relationships. She has recently become an Ambassador of Tourism through Visit Mobile. Shelli has served on several philanthropic boards; McKemie Place / Volunteer Coordinator (2013), Bras for a Cause / V.Pres and Volunteer Coordinator (2017-2021), Door to Serenity/ President and Event Coordinator (2019 – present), Board Member of the Roberts Brothers Charitable Fund (2019-2022), and Queens with Dreams as Philanthropy Director (2016-2021), all which directly serve the local community. She has chaired numerous committees raising funds for many great causes. She received the Inspiring Women’s Award (2017), Volunteer of the Month Award (Aug 2015) and the Jamie Praytor Humanitarian award from Mobile Association of Realtors (2015). Shelli has been a member of BNI (Business Networking International), a worldwide networking organization, since 2016. She also served in Big Brother/Big Sister in 2016.',
      'In 2020, Shelli had a simple yet powerful idea: to start an organization that could bridge the gap between those who needed help and those who were willing to give it. This idea gave birth to Be the Change Gulf Coast, a community driven initiative aimed at providing small but impactful assistance to individuals and families struggling to make ends meet. She imagined a community where awareness of financial struggles would inspire acts of kindness and generosity. The mission of Be The Change Gulf Coast is simple and straightforward: to mobilize the community to help its own. The specific goal is to have 5000 people donate $2/month. This modest contribution from many can significantly impact the lives of those in need. Since its inception, Be the Change Gulf Coast has made a substantial impact on the lives of many. The organization\'s approach has not only provided financial relief but also fostered a sense of solidarity and community spirit.'
    ],
    email: 'shelli@bethechangegulfcoast.com'
  },
  {
    name: 'Charlene Coffman',
    title: 'Chief Technology Officer',
    picture: char,
    bio: [
      'Charlene Coffman is the Chief Technology Officer (CTO) at Be The Change Gulf Coast. With a background in computer science and extensive experience in full-stack development, Charlene excels in leveraging both traditional and cutting-edge technologies to deliver innovative solutions.',
      'Charlene holds a B.S. in Computer Science from the University of South Alabama and has also served as a volunteer teacher for the TEALS program, a Microsoft Philanthropies initiative that builds sustainable computer science programs in high schools.',
      'Beyond her professional achievements, Charlene is a dedicated wife and mother of three. She enjoys making art through various mediums and is driven by a strong sense of social justice, striving to make a positive impact in her community and beyond.'
    ],
    email: 'charlene@bethechangegulfcoast.com'
  },
  {
    name: 'Carol Jackson',
    title: 'Board Treasurer',
    picture: carol,
    bio: [
      'Carol is originally from Louisiana and moved to Mobile in 2004. She has two daughters, Cathrine and Morgen. She loves spending time with them and has been a single mom for years. Her family is the reason she does what she does every day. She enjoys listening to music, watching movies, and playing card games with her daughters.',
      'Before graduating from the University of South Alabama in December 2014 with a B.S. in Accounting, Carol worked as a payroll administrator with a large steel construction company in Mobile, AL. In 2011, Ms. Jackson started working with James T Murray, III, CPA, LLC as a staff accountant. Since that time, she obtained experience in accounting, bookkeeping, tax and payroll services. She is a member of the University of South Alabama chapter of Beta Alpha Psi, an honor organization for financial information students and professionals.'
    ],
    email: 'carol@bethechangegulfcoast.com'
  },
  {
    name: 'Janet Frazier',
    title: 'Board Secretary',
    picture: janet,
    bio: [
      'Janet is originally from Atmore, Alabama and moved to Mobile, Alabama in 1972. She is married to Bernie Frazier and lives in Irvington, Alabama with him, their two labradors (Bryant and Mollie) and 20 chickens. She has one daughter who is married to Thomas Sigler, two grandsons who are both married to wonderful women and one great-grandson. Janet is a retired banker and now runs the day-to-day operations of J and B Guns from her house with her husband.', 'Janet has a BS in accounting from the University of South Alabama; is a certified handgun instructor; serves on her church’s Board of Stewards and the Finance Committee.'
    ],
    email: 'janet@bethechangegulfcoast.com'
  },
  {
    name: 'Jeffrey Sport',
    title: 'Legal Counsel',
    picture: jeff,
    bio: ['Practicing attorney at the Law Firm of Jeffrey R. Sport, a general practice focusing on civil litigation; wills, trusts and estates; and real property matters. Married for 41 years to Lynn, with three grown children, two grandchildren, two dogs, Marshall and Tucker, and four grand-dogs. Jeff gives time to the Mobile Bar Association’s Volunteer Lawyer’s Program and is also a board member for Habitat for Humanity.'
    ],
    email: 'jeff@bethechangegulfcoast.com'
  },
  {
    name: 'Vicki Bowers',
    title: 'Director of Client Services',
    picture: vicki,
    bio: ['Vicki Bowers is a veteran social worker, now retired. She is passionate about social justice issues and volunteering in the community to serve those who are struggling to get their needs met. Vicki serves as Community Liaison. Her role is to work with those seeking assistance by providing information and referral to local Community Resources. When help cannot be found otherwise she recommends they receive a micro donation from Be The Change Gulf Coast.'
    ],
    email: 'vicki@bethechangegulfcoast.com'
  },
  {
    name: 'Darrell Manning',
    title: 'Director of Fundraising',
    picture: darrell,
    bio: ["Darrell Manning is a Licensed Professional Counselor – Supervisor in private practice with 40 years of experience. He received his Bachelor’s and Master’s Degrees in Clinical/Organizational Psychology from the University of South Alabama. His MBA studies were at The University of Alabama-Birmingham.", "Darrell was born and raised in Mobile, Alabama. After graduating from college, he began his career as a Licensed Professional Counselor and Business Development/Human Resources Specialist working with some of this area’s largest companies, including Regions Bank, Alabama Power, and BE&K Construction. He became the Director of the St. Clair County Mental Health Center in 1991, leading a team to develop and provide multiple programs.", "Darrell has been a Supervising LPC for over 30 years, training and supervising Associate Licensed Counselors seeking full licensure. He is a Certified Gambling Addiction Counselor, EAP Specialist, and EMDR trained.", "Darrell is a founding board member of the St. Clair Co. Children’s Advocacy Center and has served on numerous other boards. He is a member of the 2009 Class of Leadership Mobile. He enjoys traveling, backpacking, and kayaking. He also enjoys writing and producing music for digital media."
    ],
    email: 'darrell@bethechangegulfcoast.com'
  },
  {
    name: 'Docia Miskov',
    title: 'Director of Marketing & Social Media',
    picture: docia,
    bio: ['A Mobile, Alabama native, Docia Miskov is a proud graduate of the University of Mobile and the University of South Alabama. She spent 19 years as an educator in the Mobile Public School System before transitioning into entrepreneurship.', 'As the founder of EVENTSOUTH and former owner of StaffBar Company, Docia has been a driving force in event production, hospitality, and marketing. She specializes in website design, social media training, and content creation, helping businesses grow their digital presence.', 'Currently, she is a dedicated real estate agent with IXL Real Estate, leveraging her creativity and business expertise in the housing market.', 'Passionate about giving back, Docia actively supports Bras for a Cause Gulf Coast, the Leukemia & Lymphoma Society, the National Wild Turkey Federation, Better Mental Health Care in Mobile, and Mobile BayKeeper.', 'Above all, she is a proud mother of two boys.', 'With a diverse background in education, business, and real estate, Docia Miskov remains committed to making a meaningful impact in both her professions and community.'
    ],
    email: 'docia@bethechangegulfcoast.com'
  }
] as Member[];

export const BoardPage: React.FC = () => {
    return <BoardMembers members={members} />;
};
