import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import AssistanceRequest from "../../Domain/AssistanceRequest";
import { UserProfileType } from "../../Domain/UserProfile";
import { Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CustomCircularProgress from "../shared/CustomCircularProgress";
import { getNumericAsCurrencyString } from "../../Helpers/StringHelpers";
import { ApplicationStatus } from "../../Domain/ApplicationStatus";
import { ActionDrawer } from "../shared/ActionDrawer";
import OrganizationDrawerContent from "./OrganizationDrawerContent";
import { VerificationStatus } from "../../Domain/VerificationStatus";
import AssistanceRequestDrawerContent from "./AssistanceRequestDrawerContent";
import { AuthContext } from "../Authentication";

const WidgetHeaders = styled('div')(() => ({
  fontWeight: "bold",
  paddingBottom: ".25em",
  fontSize: "1em",
}));

const StyledPaper = styled(Paper)(() => ({
  padding: "1em",
  boxShadow: "0px 4px 20px rgba(128, 0, 128, 0.5)",
}));

interface INewItems {
  newOrInProgressAssistanceRequests?: AssistanceRequest[];
  newOrganizations?: UserProfileType[];
  onAssistanceRequestStatusUpdate: (requestId: string, status: ApplicationStatus, completionDate?: string, payoutNotes?: string) => Promise<void>;
  onOrganizationVerificationStatusUpdate: (userId: string, status: VerificationStatus) => Promise<void>;
  onDocumentationUpdated: (requestId: string, newS3Key: string) => Promise<void>;
  isLoading: boolean;
}

const NewItems: React.FC<INewItems> = ({ newOrInProgressAssistanceRequests, newOrganizations, onAssistanceRequestStatusUpdate, onOrganizationVerificationStatusUpdate, onDocumentationUpdated, isLoading }) => {
  const [anyArs, setAnyArs] = useState<boolean>(false);
  const [anyOrgs, setAnyOrgs] = useState<boolean>(false);
  const [anythingAtAll, setAnythingAtAll] = useState<boolean>(false);
  const user = useContext(AuthContext);
  useEffect(() => {
    if ((newOrInProgressAssistanceRequests?.length ?? 0) > 0) {
      setAnyArs(true);
      setAnythingAtAll(true);
    }
    if ((newOrganizations?.length ?? 0) > 0) {
      setAnyOrgs(true);
      setAnythingAtAll(true);
    }
  }, [newOrInProgressAssistanceRequests, newOrganizations]);

  return (
    <StyledPaper>
      {isLoading && (<CustomCircularProgress message="Checking for new items" />)}
      {!isLoading && anythingAtAll && (
        <Grid container>
          <Grid item xs={12}>
            <Box width="auto">
              <WidgetHeaders>New Organizations</WidgetHeaders>
              {anyOrgs && (
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "2%" }} padding="none"></TableCell>
                      <TableCell padding="none">Organization Name</TableCell>
                      <TableCell padding="none">Representative Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newOrganizations?.map(org => 
                      <TableRow key={org.Id}>
                        <TableCell style={{ width: "2%" }} padding="none">
                          <ActionDrawer label={user?.isReadWriteAdministrator ? "Review" : "View"} drawerContent={<OrganizationDrawerContent userProfile={org} onStatusChange={onOrganizationVerificationStatusUpdate} />} />
                        </TableCell>
                        <TableCell padding="none">{org.organizationName}</TableCell>
                        <TableCell padding="none">{org.representativeName}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
              {!anyOrgs && <>No new organizations</>}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <WidgetHeaders>New Assistance Requests</WidgetHeaders>
              {anyArs && (
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "2%" }} padding="none"></TableCell>
                      <TableCell padding="none">Status</TableCell>
                      <TableCell padding="none">Organization Name</TableCell>
                      <TableCell padding="none">Amount Requested</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newOrInProgressAssistanceRequests?.map(ar =>
                      <TableRow key={ar.Id}>
                        <TableCell style={{ width: "2%" }} padding="none">
                          <ActionDrawer label={user?.isReadWriteAdministrator ? "Review" : "View"} drawerContent={<AssistanceRequestDrawerContent assistanceRequest={ar} onStatusChange={onAssistanceRequestStatusUpdate} onDocumentationUpdated={onDocumentationUpdated} />} />
                        </TableCell>
                        <TableCell padding="none">{ar.status ? ApplicationStatus[ar.status] : ""}</TableCell>
                        <TableCell padding="none">{ar.organizationName}</TableCell>
                        <TableCell padding="none">{getNumericAsCurrencyString(ar.amountRequested)}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
              {!anyArs && <>No new assistance requests</>}
            </Box>
          </Grid>
        </Grid>
      )}
    </StyledPaper>
  );
};

export default NewItems;