import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../Authentication";
import { Button, CircularProgress, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router";
import { RouteConstants } from "../../Constants/RouteConstants";
import { styled } from "@mui/material/styles";
import { createTestimonialNew, Testimonial } from "../../Domain/Testimonial";
import { createTestimonial, getS3Item } from "../../Clients/BeTheChangeApi";
import LabelAndTextDisplay from "../shared/LabelAndTextDisplay";
import { MultiLineTextInput } from "../shared/MultiLineTextInput";
import TestimonialCard from "../TestimonialCard";
import { useAuth0 } from "@auth0/auth0-react";
import { GetLoginWithRedirectParams } from "../../Constants/Auth0Constants";

const OuterDiv = styled(Box)(() => ({
  padding: "5vw",
  paddingLeft: "10vw",
}));

const AlignLeft = styled(Box)({
  textAlign: "left",
});

const AddTopPadding = styled(Box)({
  paddingTop: "1em",
});

const LogoStatement = styled(Grid)({
  textAlign:"left",
  backgroundColor:"lightgray"
});

const CreateTestimonial: React.FC = () => {
  const userFromContext = useContext(AuthContext);
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  var instructions = "Write a simple statement about a specific way in which Be The Change Gulf Coast has helped a client in your organization. Be specific about items purchased and dollar amounts if possible.";
  instructions += " Be sure to leave out any personally identifiable information like names of people or business (other than your own). This testimonial will appear on our website for donors and other organizations to see.";
  instructions += " Do not include your name as a signoff as we will display your name and the name of your organization beside the statement."

  const [testimonial, setTestimonial] = useState<Testimonial>(createTestimonialNew());
  const [loading, setLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState<any>();

  useEffect(() => {
    if(userFromContext && userFromContext.accessToken && userFromContext.userProfile && userFromContext.userProfile.logoS3Key){
        getS3Item(userFromContext.accessToken, userFromContext.userProfile.logoS3Key).then(response => {
            setLogoUrl(URL.createObjectURL(response));
        });
    }
    else if(!isLoading && !isAuthenticated){
      loginWithRedirect(GetLoginWithRedirectParams(RouteConstants.AddTestimonial));
    }
  }, [userFromContext, loginWithRedirect, isLoading, isAuthenticated])

  const inputChange = (value: any) => {
    setTestimonial({
        ...testimonial,
        statement: value,
      } as Testimonial)
  };

  const handleTestimonialSubmit = () => {
    setLoading(true);
    if (!!testimonial.statement && testimonial.statement.length > 20) {
        createTestimonial(userFromContext?.accessToken!, testimonial)
        .then(() => postSaveStuff());
    }
  };

  const postSaveStuff = () => {
    navigate(RouteConstants.Portal);
  }

  return (
    <div>
      {loading && <CircularProgress />}
      {!loading && (
        <OuterDiv>
          <Grid container justifyContent="flex-start">
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                    <LabelAndTextDisplay label="Instructions" text={instructions} align="left"/>
                </Grid>
                <AlignLeft>
                  <Grid container spacing={3}>
                    <Grid item>
                      <MultiLineTextInput
                        name="statement"
                        value={testimonial.statement}
                        onChange={inputChange}
                        label="Statement"
                        required
                      />
                    </Grid>
                  </Grid>
                </AlignLeft>
                {testimonial.statement && (
                    <Grid item xs={12} style={{paddingTop:"1vw"}}>
                        <LabelAndTextDisplay label="Example"/>
                        {userFromContext?.userProfile && !userFromContext?.userProfile?.logoS3Key && <Grid container><LogoStatement item>If you would like to have your logo replace the bee silhouette <a href={RouteConstants.Profile}>click here to edit your profile</a> and upload a logo image.</LogoStatement></Grid>}
                        <TestimonialCard 
                            statement={testimonial.statement}
                            representativeName={userFromContext?.userProfile?.representativeName!}
                            organizationName={userFromContext?.userProfile?.organizationName!}
                            logoUrl={logoUrl}
                            dateEntered={new Date()}
                        />
                    </Grid>
                )}
                <Grid container>
                  <Grid item xs={12}>
                    <AddTopPadding>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <Button variant="contained" color="error" onClick={() => navigate(RouteConstants.PortalRouter)}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" color="primary" onClick={handleTestimonialSubmit} disabled={!testimonial.statement || !(testimonial.statement.length > 20)}>
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </AddTopPadding>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </OuterDiv>
      )}
    </div>
  );
};

export default CreateTestimonial;