export const GetValue = (key: string): string|null => {
    return sessionStorage.getItem(key);
}

export const StoreValue = (key: string, value: string) => {
    sessionStorage.setItem(key, value);
}

export const ClearValue = (key: string) => {
    sessionStorage.removeItem(key);
}

export const ClearAll = () => {
    sessionStorage.clear();
}

export const CheckExists = (key: string) => {
    var something = sessionStorage.getItem(key);
    return !something === undefined && !something === null;
}

export enum StorageKey {
    UserInfo = "user-info",
    UserProfile = "user-profile",
    Token = "auth0-token",
    IsLoginRequested = "is-login-requested",
    IsAdmin = "is-admin",
    EmailAddress = "email-address",
    IsAuthenticating = "is-currently-authenticating",
    TestimonialsKey = 'testimonials',
    NumberOfDonorsKey = "numberOfMonthlyDonors",
}