import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { ButtonType } from "../shared/ButtonType";
import ButtonGroupWithLoading from "../shared/ButtonGroupWithLoading";
import AssistanceRequest from "../../Domain/AssistanceRequest";
import { ApplicationStatus } from "../../Domain/ApplicationStatus";
import { updateAssistanceRequestStatus } from "../../Clients";
import { AuthContext } from "../Authentication";
import { MultiLineTextInput } from "../shared/MultiLineTextInput";
import { getNumericAsCurrencyString } from "../../Helpers/StringHelpers";
import AssistanceRequestDocumentation from "../shared/AssistanceRequestDocumentation";
import { sendAssistanceRequestApprovedEmail, sendAssistanceRequestNotApprovedEmail } from "../../Clients/EmailClient";
import { getOrganizationEmail } from "../../Clients/BeTheChangeApi";

const Titles = styled('span')(() => ({
  fontWeight: "bold",
}));

const Values = styled('span')(() => ({}));

export interface IAssistanceRequestDrawerContent {
  assistanceRequest: AssistanceRequest;
  onStatusChange: (requestId: string, status: ApplicationStatus, completionDate?: string, payoutNotes?: string) => Promise<void>;
  onDocumentationUpdated: (requestId: string, s3Key: string) => Promise<void>; 
}

const AssistanceRequestDrawerContent: React.FC<IAssistanceRequestDrawerContent> = ({ assistanceRequest, onStatusChange, onDocumentationUpdated }) => {
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
  const [localPayoutNotes, setLocalPayoutNotes] = useState<string>("");
  const isNewOrProcessing = assistanceRequest.status === ApplicationStatus.New || assistanceRequest.status === ApplicationStatus.Processing;
  const [isReadOnly, setIsReadOnly] = useState<boolean>();
  const userFromContext = useContext(AuthContext);

  useEffect(() => {
    if (userFromContext?.isReadOnlyAdministrator) {
      setIsReadOnly(true);
    }
  }, [userFromContext]);
  
  const statusUpdate = async (status: ApplicationStatus) => {    
    setIsUpdatingStatus(true);
    var completionDate = status !== ApplicationStatus.Completed ? '' : new Date().toDateString();
    var orgEmail = await getOrganizationEmail(userFromContext?.accessToken!, assistanceRequest.userId);
    await updateAssistanceRequestStatus(userFromContext?.accessToken!, status, assistanceRequest.Id, completionDate, localPayoutNotes);
    if(status === ApplicationStatus.Completed){
      //send email
      await sendAssistanceRequestApprovedEmail(orgEmail, assistanceRequest, localPayoutNotes);
    }
    else if(status === ApplicationStatus.Cancelled){
      //send other email
      await sendAssistanceRequestNotApprovedEmail(orgEmail);
    }
    onStatusChange(assistanceRequest.Id, status, completionDate, localPayoutNotes).then(() => setIsUpdatingStatus(false));
  };

  const onChangeInput = (value: string) => {
    setLocalPayoutNotes(value);
  }
  
  const newRequestButtonList = [
    { label: "Complete", onClick: () => statusUpdate(ApplicationStatus.Completed), disable: !localPayoutNotes } as ButtonType,
    { label: "Reject", onClick: () => statusUpdate(ApplicationStatus.Cancelled), color: "error" } as ButtonType,
  ] as ButtonType[];

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} style={{ paddingBottom: ".5em" }}>
          <Titles>Amount Requested: </Titles>
          <Values>{getNumericAsCurrencyString(assistanceRequest.amountRequested)}</Values>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: ".5em" }}>
          <Titles>Purpose: </Titles>
          <Values>{assistanceRequest.purpose}</Values>
        </Grid>
        <Grid item xs={12}>
          <Titles>Status: </Titles>
          <Values>{ApplicationStatus[assistanceRequest.status ?? 0]}</Values>
        </Grid> 
        <Grid item xs={12}>
          <Titles>SubmittedBy: </Titles>
          <Values>{assistanceRequest.representativeName}</Values>
        </Grid>       
        <Grid item xs={12}>
          <Titles>Requested Distribution Date: </Titles>
          <Values>{assistanceRequest.requestedDistributionDate}</Values>
        </Grid>
        {assistanceRequest.projectCompletionDate && (
          <Grid item xs={12}>
            <Titles>Project Completion Date: </Titles>
            <Values>{assistanceRequest.projectCompletionDate}</Values>
          </Grid>
        )}        
        {!isNewOrProcessing && (
          <Grid item>
            <Titles>Payout Notes: </Titles>
            <Values>{assistanceRequest.payoutNotes}</Values>
          </Grid>
        )}        
      </Grid>
      <AssistanceRequestDocumentation 
        assistanceRequestS3Key={assistanceRequest.s3Key} 
        assistanceRequestId={assistanceRequest.Id} 
        onDocumentationUpdated={onDocumentationUpdated} 
        isReadonly={isReadOnly} 
      />
      {isNewOrProcessing && !isReadOnly && (
        <>
          <Grid item style={{ paddingTop: "1em" }}>
            <MultiLineTextInput label="Payment Notes" value={localPayoutNotes} onChange={onChangeInput} size="medium" />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "3em" }}>
            <Grid container justifyContent="flex-end">
              <Grid item>            
                <ButtonGroupWithLoading buttons={isNewOrProcessing ? newRequestButtonList : []} isLoading={isUpdatingStatus} />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}    
    </React.Fragment>
  );
};

export default AssistanceRequestDrawerContent;