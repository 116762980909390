import { Routes, Route } from 'react-router-dom';
import Splash from './Home/Splash';
import Donate from './Donate';
import OurPurpose from './OurPurpose';
import ApplyForAssistance from './Portal/ApplyForAssistance';
import Contact from './Contact';
import UserProfileForm from './Portal/UserProfileForm';
import AssistanceRequests from './Portal/AssistanceRequests';
import Administration from './AdministrationPortal/Administration';
import SpeakingOpportunities from './SpeakingOpportunities';
import React from 'react';
import { NewNonProfitWizard, RegistrationSteps } from './Registration';
import { RouteConstants } from '../Constants/RouteConstants';
import PortalRouter from './Portal/PortalRouter';
import Portal from './Portal/Portal';
import { BoardPage } from './BoardMembers/BoardPage';
import PrivacyPolicy from './PrivacyPolicy';
import StateDisclosures from './StateDisclousures';
import TermsAndConditions from './SiteTerms';
import Login from './Login';
import CreateTestimonial from './Portal/CreateTestimonial';
import EditProfileAdmin from './EditProfileAdmin';

export const AllRoutes:React.FC = () => {
  return (
    <Routes>
      <Route path={RouteConstants.Home} element={<Splash />} />
      <Route path={RouteConstants.Donate} element={<Donate />} />
      <Route path={RouteConstants.Donate + "/:amount"} element={<Donate />} />
      <Route path={RouteConstants.Apply} element={<ApplyForAssistance />} />
      <Route path={RouteConstants.NotRegistered} element={<NewNonProfitWizard />} />
      <Route path={RouteConstants.RegistrationSteps} element={<RegistrationSteps />} />
      <Route path={RouteConstants.OurPurpose} element={<OurPurpose />} />
      <Route path={RouteConstants.StateDisclosures} element={<StateDisclosures />} />
      <Route path={RouteConstants.SpeakingOpportunities} element={<SpeakingOpportunities />} />
      <Route path={RouteConstants.Contact} element={<Contact />} />
      <Route path={RouteConstants.Profile} element={<UserProfileForm />} />
      <Route path={RouteConstants.History} element={<AssistanceRequests />} />
      <Route path={RouteConstants.Admin} element={<Administration />} />
      <Route path={RouteConstants.Portal} element={<Portal />} />
      <Route path={RouteConstants.PortalRouter} element={<PortalRouter />} />
      <Route path={RouteConstants.Board} element={<BoardPage/>} />
      <Route path={RouteConstants.PrivacyPolicy} element={<PrivacyPolicy />} />
      <Route path={RouteConstants.SiteTerms} element={<TermsAndConditions />} />
      <Route path={RouteConstants.Login} element={<Login />} />
      <Route path={RouteConstants.AddTestimonial} element={<CreateTestimonial />} />
      <Route path={RouteConstants.EditProfileAdmin + "/:userId"} element={<EditProfileAdmin />} />
    </Routes>
  );
}
