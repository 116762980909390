import React, { useState, useEffect, useContext } from "react";
import AssistanceRequest from "../../Domain/AssistanceRequest";
import { Grid, Input, Switch, FormControlLabel, Button, Typography, TextField, styled } from "@mui/material";
import { ApplicationStatus } from "../../Domain/ApplicationStatus";
import { createAssistanceRequest, uploadFilesToS3 } from "../../Clients";
import { AuthContext } from "../Authentication";
import { MultiLineTextInput } from "../shared/MultiLineTextInput";
import { DateInput } from "../shared/DateInput";
import { UploadEvidence } from "./UploadEvidence";
import { useNavigate } from "react-router-dom";
import { VerificationStatus } from "../../Domain/VerificationStatus";
import { RouteConstants } from "../../Constants/RouteConstants";
import CustomCircularProgress from "../shared/CustomCircularProgress";
import { NumericFormat } from "react-number-format";
import { sendAssistanceRequestedEmail } from "../../Clients";


const MainContainerGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "1em",
  },
  [theme.breakpoints.up("md")]: {
    padding: "5em",
  },
  paddingLeft: "10em",
}));


const PurposeGridItem = styled(Grid)(() => ({
  textAlign: "left",
  paddingTop: "1em",
}));

const DateAndAmountGridItem = styled(Grid)(() => ({
  textAlign: "left",
  paddingTop: "1em",
}));

const PurposeDisclaimerTextSpan = styled('span')(() => ({
  fontWeight: 600,
  backgroundColor: "yellow",
  textDecoration: "underline",
}));

const MediumLongNumericInputBox = styled(NumericFormat as any)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "auto",
  },
  [theme.breakpoints.up("sm")]: {
    width: "20em",
  },
}));

const DescisionDisclaimerP = styled('p')(() => ({
  textAlign: "left",
  paddingTop: "1em",
}));

const AgreeToTermsSwitchGridItem = styled(Grid)(() => ({
  textAlign: "left",
}));

const AgreeToTermsStatementGridItem = styled(Grid)(() => ({
  textAlign: "left",
}));

const RequiredFieldsMissing = styled('span')(() => ({
  color: "red",
  textAlign: "left",
}));


const AgreeToTermsStatementDiv = styled('div')(() => ({
  backgroundColor: "lightgray",
  padding: "1em",
  textAlign: "center",
}));

const SignatureNameReadOnlyInput = styled(Input)(() => ({
  fontSize: 16,
}));

const SignatureOrganizationReadOnlyInput = styled(Input)(({ theme }) => ({
  fontSize: 16,
  [theme.breakpoints.down("xs")]: {
    width: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    width: "13.5em",
  },
  [theme.breakpoints.up("sm")]: {
    width: "20em",
  },
}));

const ButtonGridItem = styled(Grid)(() => ({
  paddingTop: "1em",
}));

const ApplyForAssistance: React.FC = () => {
  const [request, setRequest] = useState<AssistanceRequest>(new AssistanceRequest());
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showValidationFailed, setShowValidationError] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState([] as File[]);

  const [showPurposeValidationMessage, setShowPurposeValidationMessage] = useState(false);
  const [showRequestedDateValidationMessage, setShowRequestedDateValidationMessage] = useState(false);
  const [showAmountValidationMessage, setShowAmountValidationMessage] = useState(false);
  const [showErrorAfterSubmit, setShowErrorAfterSubmit] = useState(false);

  const user = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if(!user?.isLoading && user?.userProfile?.verificationStatus === VerificationStatus.Approved){
      setRequest((prevRequest) => ({
        ...prevRequest,
        requestedDistributionDate: new Date().toDateString(),
        status: ApplicationStatus.New,
        organizationName: user.userProfile!.organizationName ?? "",
        representativeName: user.userProfile!.representativeName ?? "",
        userId: user.userProfile!.Id ?? "",
      }));
      setLoading(false);
    }
    else if(!user?.isLoading) {
      navigate(RouteConstants.PortalRouter);
    }
  }, [user, navigate]);

  
  const onPurposeChange = (newValue: string) => {
    setFieldOnAssistanceRequestInState("purpose", newValue);
  }

  const onRequestedDateChange = (newDate: Date | undefined) => {
    setFieldOnAssistanceRequestInState("requestedDistributionDate", newDate?.toDateString());
  }
  
  const amountRequestedChange = (event: any) => {
    var amountAsStringWithCurrency = event.target.value;
    if(amountAsStringWithCurrency){
      var amount = parseFloat(amountAsStringWithCurrency.replace("$", ""));
      setFieldOnAssistanceRequestInState("amountRequested", amount);
      setShowAmountValidationMessage(false);
    }
    else {
      setFieldOnAssistanceRequestInState("amountRequested", 0.00);
    }
  }

  const updateFileList = (files: File[] | undefined) => {
    if(files){
      setFilesUploaded(files);
    }
  };

  const allRequiredFieldsValidated = () => {    
    var isPurposeValid = request?.purpose && request?.purpose !== "";
    var isRequestedDistributionDateValid = request?.requestedDistributionDate && request?.requestedDistributionDate >= (new Date()).toDateString();
    var isAmountValid = request?.amountRequested && request?.amountRequested > 0.0;
    
    setShowPurposeValidationMessage(!isPurposeValid);
    setShowRequestedDateValidationMessage(!isRequestedDistributionDateValid);
    setShowAmountValidationMessage(!isAmountValid);
    
    return isPurposeValid && isRequestedDistributionDateValid && isAmountValid;
  };

  const handleAgreeToTerms = (e: any) => {
    var isCheckedTrue = e.target.checked;
    if (isCheckedTrue) {
      if (allRequiredFieldsValidated()) {
        setShowValidationError(false);
        setFieldOnAssistanceRequestInState("agreedToTerms", true);
        setFieldOnAssistanceRequestInState("signatureName", user?.userProfile?.representativeName);
        setFieldOnAssistanceRequestInState("signatureOrganization", user?.userProfile?.organizationName);
      } else {
        setShowValidationError(true);
        setFieldOnAssistanceRequestInState("agreedToTerms", false);
      }
    } else {
      setShowValidationError(false);
      setFieldOnAssistanceRequestInState("agreedToTerms", false);
      setFieldOnAssistanceRequestInState("signatureName", "");
      setFieldOnAssistanceRequestInState("signatureOrganization", "");
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    var currentRequest = request!;
    currentRequest.requestDate = (new Date()).toLocaleDateString();
    if(filesUploaded && filesUploaded.length > 0){
      currentRequest.s3Key = await uploadFilesToS3(user?.accessToken!, filesUploaded);
    }
    else {
      currentRequest.s3Key = "";
    }

    createAssistanceRequest(user?.accessToken!, currentRequest)
      .then(() => {
        setIsSubmitting(false);
        sendAssistanceRequestedEmail(currentRequest, user?.emailAddress!);
        navigate(RouteConstants.PortalRouter);
      })
      .catch((error) => {
        setIsSubmitting(false);
        setShowErrorAfterSubmit(true);
      });
  };

  function setFieldOnAssistanceRequestInState(field: keyof AssistanceRequest, value: any) {
    setRequest((prev) => ({ ...prev, [field]: value }));
  }

  return (
    <div> 
      {loading && <CustomCircularProgress message="Getting user info" />}
      {isSubmitting && <CustomCircularProgress message="Validating & Submitted your request" />}
      {showErrorAfterSubmit && <>There was an error submitted your request</>}
      {!isSubmitting && !loading && (
        <MainContainerGrid container justifyContent="flex-start">
          <Grid item xs={12} md={5}>
            <Typography align="left">
              <b>Organization:</b> {user?.userProfile?.organizationName}
            </Typography>
            <Typography align="left">
              <b>Representative:</b> {user?.userProfile?.representativeName}
            </Typography>
            <Typography align="left">
              <b>Federal EIN:</b> {user?.userProfile?.federalEIN}
            </Typography>
          </Grid>
          <PurposeGridItem item xs={12}>
            <div style={{marginBottom: "1em"}}>
              <PurposeDisclaimerTextSpan>
                Please do not provide personal indetifiable information of donee, due to HIPPA regulations and laws. Avoid using names of people or places of business. Avoid gender specific language.
              </PurposeDisclaimerTextSpan>
            </div>
            <MultiLineTextInput
              label="Purpose"
              name="purpose"
              value={request?.purpose}
              onChange={onPurposeChange}
              required
              errorMessage={"Purpose is required"}
              showErrorMessage={showPurposeValidationMessage}
            />
          </PurposeGridItem>
          <DateAndAmountGridItem item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <DateInput
                  label="Requested Distribution Date"
                  value={request?.requestedDistributionDate}
                  onChange={onRequestedDateChange}
                  size="medium"
                  disablePast
                  required
                  errorMessage={"Requested Distribution Date is required"}
                  showError={showRequestedDateValidationMessage}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MediumLongNumericInputBox
                    name="amountRequested"
                    id="amountRequested"
                    customInput={TextField}
                    value={request?.amountRequested}
                    onChange={amountRequestedChange}
                    variant="outlined"
                    label="Amount Requested"
                    required={true}
                    decimalScale={2}
                    decimalSeparator="."
                    fixedDecimalScale
                    prefix={'$'}
                    min={1}
                    error={showAmountValidationMessage}
                    helperText={showAmountValidationMessage ? "Amount is required" : ""}
                  />
              </Grid>
            </Grid>
          </DateAndAmountGridItem>
          <UploadEvidence fileList={filesUploaded} updateFileList={updateFileList} />
          <DescisionDisclaimerP>
              Distributions shall be made by confidential decision of the Distribution committee with Board of Directors approval. They reserve the
              right to disqualify any organization that does not meet the distribution request requirements. The Board also reserves the right that
              all decisions are at the discretion of the Committee and Board of Directors of Be The Change Gulf Coast organization.
          </DescisionDisclaimerP>
          <AgreeToTermsSwitchGridItem item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={request?.agreedToTerms}
                  onChange={handleAgreeToTerms}
                  name="checkedB"
                  color="primary"
                  id="agreeToTerms"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="I agree"
            />
            {showValidationFailed && <RequiredFieldsMissing>*Required fields are missing</RequiredFieldsMissing>}
          </AgreeToTermsSwitchGridItem>
          <AgreeToTermsStatementGridItem item xs={12}>
            <AgreeToTermsStatementDiv>
              <span>I, </span>
              <SignatureNameReadOnlyInput value={request?.signatureName} disabled></SignatureNameReadOnlyInput>
              <span>, a representative of </span>
              <SignatureOrganizationReadOnlyInput value={request?.signatureOrganization} disabled></SignatureOrganizationReadOnlyInput>
              <span>
                agree to provide Be The Change Gulf Coast a detailed report with documentation of distributions of the funds made available. If any
                funds are not distributed as stated above, we agree to return unused funds to Be The Change Gulf Coast within 30 business days from
                above completion date.
              </span>
            </AgreeToTermsStatementDiv>
          </AgreeToTermsStatementGridItem>
          <ButtonGridItem item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" color="error" onClick={() => navigate(RouteConstants.PortalRouter)}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!request?.agreedToTerms}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </ButtonGridItem>
        </MainContainerGrid>
      )}
    </div>
  );
};

export default ApplyForAssistance;

